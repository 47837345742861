/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
$base-black-color: #040415;

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}


@mixin dotSize($size) {
  width: $size;
  height: $size;
  background-color: #c4c4c4;
}
@mixin flexCenter($justifycontent) {
  display: flex;
  align-items: center;
  justify-content: $justifycontent;
}

:root {
  --ion-default-font: "Product Sans" !important;
}

.ml-7px {
  margin-left: 7px;
}

//pop-over
.popover_setting .popover-content {
  width: 300px;
  height: 3rem;
  background: #ffaa1b;
  border-radius: 100px;
}
.pulse-modal .modal-wrapper {
  width: 328px;
  height: 295px;
  border-radius: 12px;
  position: fixed;
  margin-top: -22vh;
}
.feed-modal .modal-wrapper {
  width: 328px;
  height: 482px;
  border-radius: 12px;
  position: fixed;
}

.profile-modal .modal-wrapper {
  width: 328px;
  height: 520px;
  border-radius: 12px;
  position: fixed;
}

.account-modal .modal-wrapper {
  width: 328px;
  height: 520px;
  border-radius: 12px;
  position: fixed;
}
.action-modal .modal-wrapper {
  width: 328px;
  height: 580px;
  border-radius: 12px;
  position: fixed;
}

app-popover {
  height: 100%;
}

ion-header {
  .header-wrapper {
    background: #232f3a;
    padding: 5.5vh 5% 5% 5%;
    display: flex;
    height: 7.35rem;
    .header-title {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.2px;
      color: rgba(255, 255, 255, 0.71);
    }
    .header-name {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.5px;
      color: #ffffff;
      margin-top: 1%;
    }
    .dividier {
      width: 50%;
    }
    .header-button-wrapper {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding-left: 7.5%;
      .schedule-button {
        height: 3.4rem;
        border-radius: 50%;

        width: 3.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .notification-button {
        height: 3.4rem;
        border-radius: 50%;
        width: 3.4rem;
        background: #ffb042;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .extendedNav {
    width: 100%;
    height: 8vh;
    background: #f2f4f5;
    padding: 2.5% 6%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    button {
      width: 45%;
      height: 2.5rem;
      background-color: white;
      color: black;
      border: 1.5px solid rgba(159, 159, 159, 0.2);
      border-radius: 100px;
      font-size: 0.9rem;
    }

    .active {
      color: white;
      background: #ffb042;
    }
  }
  .extendedNav2 {
    width: 100%;
    height: 8vh;
    background: #ffffff;
    padding: 2.5% 6%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
  
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2.5rem;
      background: #ffffff;
      border-radius: 5px;
      transform: translateY(-50%);
      z-index: 0;
    }
  
    button {
      width: 45%;
      height: 2.5rem;
      background-color: transparent;
      color: black;
      border: 1px solid #ccc; /* Add border around buttons */
      border-radius: 5px;
      font-size: 0.9rem;
      position: relative;
      z-index: 1;
      transition: background-color 0.3s, color 0.3s;
  
      &.active {
        color: white;
        background: #586de6;
  
        &::after {
          content: '';
          position: absolute;
          bottom: -8px; /* Adjust this value to control the distance from the button */
          left: 0;
          width: 100%;
          height: 2px; /* Line height */
          background: #6e6e6e; /* Line color */
          z-index: 2;
        }
      }
    }
  }
}

.swiper-pagination-bullet-active {
  background-color: #4a5661 !important;
}
#home .swiper-pagination-bullet {
  @include dotSize(5px);
}
#home-details .swiper-pagination-bullet {
  @include dotSize(8px);
}

.swiper-pagination {
  margin-top: 6%;
  position: inherit;
}
.popover-translucent.sc-ion-popover-ios-h .popover-content.sc-ion-popover-ios,
.popover-translucent.sc-ion-popover-ios-h .popover-arrow.sc-ion-popover-ios::after {
  background-color: #ffaa1b;
}

app-footer {
  height: 13%;
}

.container {
  padding: 6% 5%;
  height: 100%;
  overflow-y: scroll;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
}
.container::-webkit-scrollbar {
  display: none;
}
.gotoButton {
  background: url("https://res.cloudinary.com/synicsoftware/image/upload/v1626728920/drivecv/rentanglebtton_dbhloe.png")
    no-repeat;
  cursor: pointer;
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  background-position: center;
}
.pulse-main {
  width: 100%;
  height: auto;
  margin-top: 4%;
}
.card {
  background: linear-gradient(114.91deg, #13c4e4 8.02%, #1da0ff 100%);
  border: 1px solid rgba(159, 159, 159, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  border-radius: 20px !important;
  padding: 4% 5% 7% 7% !important;
  min-height: 10rem !important;
  width: 100% !important;
  text-align: initial !important;
  color: white !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.info-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.card-title {
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: 300;
}
.card-value {
  font-size: 3.25rem;
  line-height: 3.6rem;
  font-weight: 700;
  margin: 1% 0%;
  color: #ffffff;
}
.card-stat-wrapper {
  @include flexCenter(space-between);
  margin-top: 2%;
  color: #ffffff;
}
.dot {
  font-size: 1.8rem;
  margin-right: 40%;
  vertical-align: sub;
}
.card-stat {
  text-align: center;
  width: 33%;
  color: #ffffff;

  img {
    vertical-align: top;
    margin-top: 5%;
  }
  .card-info {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
}
.break {
  flex-basis: 100%;
  height: 0;
}
.stat-container  {
  background: #ffffff;
  flex-wrap: wrap;
  border: 1px solid rgba(159, 159, 159, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 9px 20px rgba(35, 47, 58, 0.12);
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 1% 2%;
  justify-content: center;
  color: $base-black-color;
  margin-bottom: 2rem;
  .stat-content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    font-size: 0.95rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $base-black-color;
    padding: 3.5% 0% 3.5% 5%;
  }
}
.recent-posts {
  padding: 5% 5% 0% 5%;
  font-weight: bold;
  flex-direction: column;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.5px;
  
  color: #232F3A;
}
.recent-posts-credential {
  @include flexCenter(--view);
  font-size: 12px;
  font-weight: 700;
  padding: 2% 0%;
  letter-spacing: 0px;
  span {
    margin-left: 4px;
  }
  .timeline {
    color: #232f3a;
    font-weight: 500;
  }
}
.pulse-button {
  background: #ffb042;
  border-radius: 12px;
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  height: 3.7rem;
  width: 100%;
}

.training-header {
  background: #f2f4f5;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-top: 2%;
  background: #bfc1c2;
  border-radius: 6px;
}

.tab-b {
  background: white;
  border-radius: 12px;
  border: rgba(159, 159, 159, 0.2) 1px solid;
  color: black;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 48%;
  margin: 2% 1%;
}



.questionnaire-title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.7rem;
  letter-spacing: 0.5px;
  color: #161626;
}

.recent-posts-footer {
  border-top: 1px solid #ececec;
  font-size: 0.6rem;
  line-height: 0.8rem;
  display: flex;
  padding-right: -5%;
  padding-left: -5%;
  justify-content: flex-end;
  color: #232f3a;
  padding: 2.5% 5.5% 2.5% 0%;
  margin: 0 -5%;
}
.team-profile-wrapper {
  border-bottom: 1px solid #dfdfdf;
  height: 5.5rem;
}
.notifications-wrapper {
  height: 7.25rem;
  border-bottom: 1px solid #dfdfdf;
  background: white;
  padding: 12% 7% 5% 7%;
  display: flex;
  align-items: center;
}
.notifications-back {
  height: 3.4rem;
  border-radius: 50%;
  border: 0.13rem solid #a9a9a9;
  width: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-questions {
  padding: 6% 6% 6% 6%;
  height: 43rem;
  overflow-y: scroll;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.2rem;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #7f7f7f;
  div {
    // margin-top: 4.5%;
  }
  ion-item {
    --border-style: none;
    --padding-start: 0px;
    --inner-padding-end: 0px;
  }
  .positionbottom {
    margin-top: auto;
  }

  ion-select {
    border: 0.13rem solid #dfdfdf;
    --placeholder-color: black;
    --placeholder-opacity: 1;
    width: 100%;
    height: 3.5rem;
    padding-left: 1rem;
    margin-top: 4.5%;
    border-radius: 12px;
  }
  .emoji-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 13%;
    .emoji {
      border: 0.13rem solid #dfdfdf;
      box-sizing: border-box;
      border-radius: 12px;
      height: 3.7rem;
      width: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .active {
    background-color: #ffaa1b;
    border: none !important;
    color: white !important;
  }
  textarea {
    margin-top: 4.5%;
    width: 100%;
    border: 0.13rem solid #dfdfdf;
    box-sizing: border-box;
    padding: 5%;
    border-radius: 12px;
    background-color: white;
  }
  ::placeholder {
    opacity: 0.4;
    font-weight: 500;
  }
  ion-select::part(icon) {
    // opacity: 1;
    // height: 9px;
    // width: 16px;
    // margin-right: 1%;
    // margin-left: 1em;
    // color: transparent;
    // background: url("https://res.cloudinary.com/synicsoftware/image/upload/v1627250882/drivecv/Path_bly8vh.png")
    //     no-repeat center;
}
  .agree-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 3.5rem;
  }
  .agree-selection {
    width: 57%;
    border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    height: 2.5rem;
    color: black;
  }
  .questionnaire-subtitle {
    margin-top: 9%;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
}

// Chart
.circular-chart {
  display: block;
  width: 50%;
  height: 100%;

  margin-left: 5%;
}
.that-circle {
  fill: none;
  stroke-width: 3.5;
  stroke-linecap: round;
  box-shadow: 0 8px 25px 0 #e5e5e5;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.total-number {
  font-weight: bold;
  font-size: 0.65rem;
  line-height: 0.8rem;
  color: $base-black-color;
}
.total-number-text {
  font-size: 0.155rem;
  line-height: 0.4rem;
  color: $base-black-color;
  font-weight: 600;
}

.add-action {
  width: 90%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 18px;
  color: #232f3a;
  background: #ffffff;
  border: 1px solid #232f3a;
  border-radius: 12px;
  margin-top: 2rem;
  margin-bottom: 3%;
  font-weight: 700;
}
.feedmodal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3rem;
  color: #000000;
  margin-top: 3%;
}
.feedmodal-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: 		#A0A0A0;
  margin-top: 5%;
}

.notification-back-title {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #040415;
  font-weight: 700;
  margin-left:8%;
}
.notifications-content-wrapper {
  border-bottom: 1px solid #dfdfdf;
  height: 5.4rem;
  padding:3% 5%;
  display: flex;
  align-items: center;
  img{
      width: 46px;
      height: 46px;
      margin-right: 4%;
  }
  .notifications-content-title{
      font-size: 1rem;
      margin-top: 1%;
      letter-spacing: 0.5px;
  }
}
.facts {
  border-bottom: 1px solid #dfdfdf;
  min-height: 4.8rem;
  padding: 5% 6%;
  align-items: center;
  .facts-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #040415;
  }
  .facts-content {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #000000;
      margin-top: 2.5%;
  }
  .italicize {
      font-style: italic;
  }
  .rewards-container {
      overflow-x: scroll;
      display: flex;
      margin-top: 4%;
      img {
          margin-right: 5.5%;
          width: 72px;
          height: 72px;
      }
  }
}
.customer-rate {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ffaa1b;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag-container {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  min-height: 112px;
  padding: 8.2% 7% 5% 7%;
  display: flex;
  position: relative;
  flex-direction: column;
  &.open{
    margin: 0 4%;
  }
  &.closed {
    margin: 4% 4% 0 4%
  }
  &.home-datails-action{
    margin-top: 4%;
  }
  
  .flag-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      color: #040415;
      margin-bottom: 2.5%;
      display: flex;
      align-items: center;
      img {
          vertical-align: middle;
      }
      .text {
          margin-top: 1.5%;
          margin-left: 4px;
      }
  }
  .flag-subtitle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.2px;
      color: rgba(4, 4, 21, 0.5);
      //white-space: nowrap;
  }
  .flag-icon {
      position: absolute;
      top: 12px;
      right: 16px;
      max-width: 24px;
      max-height: 24px;
      object-fit: contain;
  }
}
.action-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin: 5% auto 2% 0%;
  padding-left: 7%;
  color: #000000;
}

.action-container {
  background: #ffffff;
  flex-wrap: wrap;
  box-sizing: border-box;
  border:none;
  box-shadow: none;
  width: 100%;
  display: flex;
  padding: 8% 1%;
  justify-content: center;
  color: #040415;
  .stat-content {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 0.8rem;
      font-weight: 700;
      color: #040415;
      padding: 3.5% 0% 3.5% 5%;
    }

}
.fc .fc-col-header-cell-cushion {
  font-size: 11px !important;
}
.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
  font-size: 12px !important;
}
.highcharts-credits {
  display:none  !important;
}

.card-graph-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:black;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5%;
  img {
      width: 1.5rem;
      height: 1.5rem;
  }
  button {
      background: #ffffff;
      border: 1px solid #e1e3e4;
      box-sizing: border-box;
      border-radius: 100px;
      width:3.8rem;
      height: 2rem;
      font-size: 9px;
      font-weight: 600;
  }
}
.card-graph-wrapper {
  display:flex;
  justify-content: space-between;
  align-items: center;
  button {
      white-space: nowrap;
  }
  .active {
      background-color: #ffb042;
      color: white;
      border:1px solid #ffb042;
  }
}
.sort-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color:black;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5%;
  button {
      background: #ffffff;
      border: 1px solid #e1e3e4;
      box-sizing: border-box;
      border-radius: 100px;
      width:5.8rem;
      height: 2rem;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
  }
  .active {
    background-color: #ffb042;
    color: white;
    border:1px solid #ffb042;
}
}

.alert-wrapper{
  max-width: unset !important;
  width: 90% !important;
}

.ch-number{
  font-size: 20px;
}
.noti-badge{
  min-width: 20px;
  height: 20px;
  padding: 0 5px;
  // display: inline-block;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  background-color: red;
  position: relative;
  top: -142%;
  text-align: center;
  float: left;
}
.footer-noti-badge{
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: red;
  position: relative;
  top: -8px;
  right: 4px;
}
